<template>
  <LiefengContent  @backClick="clickBar" isBack="true" :navList="navList" @clickBar="clickBar">
      <template #title>{{titleName}}</template>
      <template #toolsbarRight v-if="showBar">
          <Select  v-model="selectValue" style="width:200px" @on-change="changeSelectList">
            <Option v-for="(item,index) in selectList" :value="item.value" :key="index">{{ item.label }}</Option>
        </Select>
      </template>
      <template #contentArea>
          <Card style="margin:20px 0 ">
             <div class="card-view">
                 <CardItem style="width:400px" cardTitle="栏目开通数(个)" :list="columnList"></CardItem>
                 <CardItem style="width:300px" cardTitle="栏目数据发布情况(条)" :list="sendList" background="linear-gradient(to right,#40ACF7,#737CFF)"></CardItem>
             </div>
              <TitleColor  :title='tableTitle'>
              </TitleColor>
              <div  class="table-view">
                  <VeTable :tableData="streeUser.tableData" :tableColumn="streeUser.columns"></VeTable>
              </div>
            <TitleColor v-if="showElection" :title='columnTitle'>
              </TitleColor>
              <div v-if="showElection" class="bar-div">
                 <VeBar :showSelect="false" :data="columnUsage.barData"  :settings="columnUsage.settings"></VeBar>
             </div>
             <div v-if="showElection" class="table-view">
                 <VeTable :tableData="columnUsage.tableData" :tableColumn="columnUsage.streeColumn"></VeTable>
             </div>
            <TitleColor v-if="showElection" :title='columnTitleOther'>
              </TitleColor>
              <div v-if="showElection" class="bar-div">
                 <VeBar :showSelect="false" :data="columnUsageTwo.barData"  :settings="columnUsageTwo.settings"></VeBar>
             </div>
              <div v-if="showElection" class="table-view">
                 <VeTable :tableData="columnUsage.tableData" :tableColumn="columnUsageTwo.streeColumn"></VeTable>
             </div>

             <TitleColor v-if="!showElection" title='信息更新频率'>
              </TitleColor>
              <div v-if="!showElection" class="bar-div">
                  <div class="select-div">
                    <div @click="changeIndex(index)" :class="selectIndex == index ? 'item item-active':'item'" v-for="(item,index) in lineSelectList" :key="index">{{item.value}}</div>
                    <div v-if="selectIndex == 1">
                       <DatePicker style="margin-left:20px;width: 200px" type="daterange" format="yyyy/MM/dd" v-model="searchDate" split-panels placeholder="请选择自定义时间"></DatePicker>
                       <Button style="margin-left:10px" type="primary" @click="changeSearch">查询</Button>
                    </div>
                  </div>
                  
                  <VeLine :data="lineUsage.lineData" :settings="lineUsage.settings"></VeLine>
              </div>
             

          </Card>
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2.vue'
import TitleColor from '../components/TitleColor'
import VeRing from '../components/VeRing'
import VeTable from '../components/Table'
import VeBar from '../components/VeBar'
import CardItem from '../components/CardItem'
import VeLine from '../components/VeLine'
import {mixinRequest} from '../utils/mixinRequest'
export default {
    components:{LiefengContent,TitleColor,VeRing,VeTable,VeBar,CardItem,VeLine},
    mixins:[mixinRequest],
    data(){
        return{
            // 面包屑导航栏
            navList:[],
            // 当前页面标题
            titleName:'栏目建设情况',
            // 存放用户信息
            portalInfo:{},

            // 绑定的搜索参数
            selectValue:'',
            // 绑定的选择框列表
            selectList:[],
            

            showBar:false,

            // 顶部栏目数量
            columnList:[
            ],
            sendList:[
            ],
            thirdList:[
                {title:'链接第三方资源数',num:'16'},
            ],

            // 各街道建设情况
            streeUser:{
                tableData:[],
                columns:[],
                orgLevel:0
            },
            tableTitle:'',
            columnTitle:'',
            columnTitleOther:'',
            // 各街道栏目统计
            columnUsage:{
                ring:{
                },
                tableData:[],
                streeColumn:[
                    {
                        title: "名称",
                        key: "orgName",
                        minWidth: 200,
                        align: "center",
                        render:(h,params) => {
                                return h('div',[
                                h('a',{
                                    on:{
                                    'click':()=>{
                                        // 点击跳转
                                        this.changeSelectList(params.row.orgCode)
                                    }
                                    }
                                },params.row.orgName)
                            ])
                        }
                    },
                    {
                        title: "栏目数",
                        key: "columnAmount",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "子栏目数",
                        key: "columnChild",
                        minWidth: 200,
                        align: "center"
                    },
                ],
                barData:[],
                settings:{
                    stack:{'社区':['栏目数','子栏目数']}
                },
            },
            columnUsageTwo:{
                ring:{
                },
                tableData:[],
                streeColumn:[
                     {
                        title: "名称",
                        key: "orgName",
                        minWidth: 200,
                        align: "center",
                        render:(h,params) => {
                                return h('div',[
                                h('a',{
                                    on:{
                                    'click':()=>{
                                        // 点击跳转
                                        this.changeSelectList(params.row.orgCode)
                                    }
                                    }
                                },params.row.orgName)
                            ])
                        }
                    },
                    {
                        title: "发布数",
                        key: "total",
                        minWidth: 200,
                        align: "center"
                    },
                    {
                        title: "公布中数量",
                        key: "published",
                        minWidth: 200,
                        align: "center"
                    },
                ],
                barData:[],
                settings:{
                    stack:{'社区':['发布数量','公布中数量']}
                },  
            },
            showElection:true,

            lineUsage:{
                lineData:{},
                settings:{
                    area: true,
                    itemStyle: {
                    //面积图颜色设置
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                        {
                            offset: 0,
                            color: "rgba(254,106,172,0.6)" // 0% 处的颜色
                        },
                        {
                            offset: 1,
                            color: "rgba(255,139,119,0.1)" // 100% 处的颜色
                        }
                        ],
                        globalCoord: false // 缺省为 false
                    }
                    }
                }
            },

            selectIndex:0,
            lineSelectList:[
                {value:'近30天'},
                {value:'自定义时间'},
            ],

            // 自定义时间
            searchDate:[]
        }
    },
   async created(){
        this.portalInfo = this.$store.state.portal ? this.$store.state.portal : {}
        if(this.portalInfo){
            if(this.portalInfo.orgLevel == 4){
                // 当前是街道，进来是社区
                this.titleName = this.portalInfo.orgName + '街道栏目建设情况'
                this.tableTitle = '各社区建设情况'
                this.columnTitle = '各社区栏目统计'
                this.columnTitleOther = '社区发布各类别信息数量统计'
               
            }else if(this.portalInfo.orgLevel == 3){
                // 如果当前是区，进来是街道
                this.titleName = this.portalInfo.orgName + '栏目建设情况'
                this.tableTitle = '各街道建设情况'
                this.columnTitle = '各街道栏目统计'
                 this.columnTitleOther = '街道发布各类别信息数量统计'
            }
             this.showBar = true
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
        }
        
        this.navList.push(...(JSON.parse(this.$route.query.router)),{
            name:this.titleName,
            router:this.$route.path
        })
        await this.getStree()
    },
    methods:{
        // 搜索时间后的查询按钮
        async changeSearch(){
            if(this.searchDate[0] == ''){
                this.$Message.warning({
                    content:'请选择日期后进行查询',
                    background:true
                })
                return
            }
            let dataTwo = await this.selectColumnFrequency({
                    orgCode:this.navList[this.navList.length - 1].orgCode,
                    startDate:this.$core.formatDate(new Date(this.searchDate[0]),'yyyy-MM-dd'),
                    endDate:this.$core.formatDate(new Date(this.searchDate[1]),'yyyy-MM-dd')
            })
            this.formatterPinlv(dataTwo)
        },
        // 点击时间范围框
       async changeIndex(index){
            this.selectIndex = index
            if(index == 0){
                this.searchDate = []
                let dateThird = this.getThirdMonth()
                let dataTwo = await this.selectColumnFrequency({
                    orgCode:this.navList[this.navList.length - 1].orgCode,
                    startDate:dateThird.startDate,
                    endDate:dateThird.endDate
                })
                this.formatterPinlv(dataTwo)
            }
        },
        // 搜索选择框change回调事件
       async changeSelectList(val){
           console.log(val)
           this.showElection = true
           if(val && this.selectList.length > 0){
               this.selectList.map(async item =>{
                   if(val == item.value){
                       if(!item.dimensionId){
                           if(item.orgLevel == '4' ){
                            this.showBar = true
                            this.titleName = '街道栏目建设情况'
                            this.selectList =  this.formatterData(await this.getSelectList(val))
                            if(this.navList[this.navList.length - 1].orgLevel && this.navList[this.navList.length - 1].orgLevel == '4'){
                                return
                            }
                            }else if(item.orgLevel == '5'){
                                this.titleName = '社区栏目建设情况'
                                this.showBar = true
                                this.selectList = this.formatterOrgData(await this.getOrgManages(val))
                                    if(this.navList[this.navList.length - 1].orgLevel && this.navList[this.navList.length - 1].orgLevel == '3'){
                                    return
                                }
                            }else{
                                    this.showBar = false
                            } 
                            this.navList.push({
                                name:this.titleName,
                                router:this.$route.path,
                                orgLevel:item.orgLevel,
                                orgCode:val
                            })
                            this.getChange()
                            if(item.orgLevel == '4'){
                                await this.getColumnNum(this.navList[this.navList.length - 1].orgCode)
                            }else if(item.orgLevel == '5'){
                                await this.getColumnNum(this.navList[this.navList.length - 1].orgCode,'2')
                            }
                            
                            return
                       }else{
                            this.showBar = false
                            this.showElection = false
                            this.titleName = item.label + '栏目建设情况'
                            this.tableTitle = '各管理员使用情况'
                            this.navList.push({
                                name:this.titleName,
                                router:this.$route.path,
                                orgLevel:item.orgLevel,
                                orgCode:val,
                                dimensionId:item.dimensionId
                            })
                            this.navList = [...new Set(this.navList.map(t=>JSON.stringify(t)))].map(s=>JSON.parse(s))
                           await this.getColumnNum(this.navList[this.navList.length - 1].orgCode,'1')   
                           return 
                       }                                    
                   }
               })
               
           }
            
            
           
                   
        },

        async clickBar(item,index){
            console.log(item,index)
            if(!item){
                // 点击的是返回按钮
                this.navList.splice(this.navList.length - 1,1)
               
            }else{
                this.navList.splice(index + 1,(this.navList.length - index))
            }
             this.titleName = this.navList[this.navList.length - 1].name
             this.showBar = true
             this.selectValue = ''
             await this.getBack()
             await this.getBackChange()
             

        },

      async getBack(){
          this.showElection = true
          if(this.navList && this.navList.length > 0){
              if(this.navList[this.navList.length - 1].orgLevel){
                  if(this.navList[this.navList.length -1].orgLevel == '5'){
                      this.selectList = this.formatterOrgData(await this.getOrgManages(this.navList[this.navList.length -1].orgCode))
                  }else{
                      this.selectList = this.formatterData(await this.getSelectList(this.navList[this.navList.length -1].orgCode))
                  }
              }else if(!this.navList[this.navList.length - 1].orgLevel){
                  this.selectList = this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
              }
          }
      },
        // 街道建设情况数据处理
       async getStree(){
             // 判断是否获取当了当前用户的信息
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                // 判断当前维度
                //  let data = await this.getSelectOrgChildInfo(this.portalInfo.orgCode)
                 let dataTwo = await this.leaveOrgChildInfo(this.portalInfo.orgCode)
                 await this.getColumnNum(this.portalInfo.orgCode)
                if(this.portalInfo.orgLevel == 3){
                    // 社区
                  this.tableTitle = '各街道建设情况'
                  this.columnTitle = '各街道栏目统计'
                  this.columnTitleOther = '街道发布各类别信息数量统计'
                   this.streeUser = {
                        tableData:dataTwo && dataTwo.length > 0 ? dataTwo : [],
                        columns:[
                            {
                                title:'街道',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        on:{
                                        'click':()=>{
                                            // 点击跳转
                                            this.changeSelectList(params.row.orgCode)
                                        }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
                }else if(this.portalInfo.orgLevel == 4){
                    // 街道
                     this.tableTitle = '各社区建设情况'
                     this.columnTitle = '各社区栏目统计'
                     this.columnTitleOther = '社区发布各类别信息数量统计'
                     this.streeUser = {
                        tableData:dataTwo && dataTwo.length > 0 ? dataTwo : [],
                        columns:[
                            {
                                title:'社区',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        on:{
                                        'click':()=>{
                                            // 点击跳转
                                            this.changeSelectList(params.row.orgCode)
                                        }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
                }
            }
      },
       async getChange(){ 
            // let data =  await this.getSelectOrgChildInfo(this.navList[this.navList.length - 1].orgCode)
            
            if(this.navList[this.navList.length - 1].orgLevel == '4'){
                let dataTwo = await this.leaveOrgChildInfo(this.navList[this.navList.length - 1].orgCode)
                // 社区
                 this.tableTitle = '各社区建设情况'
                 this.columnTitle = '各社区栏目统计'
                 this.columnTitleOther = '社区发布各类别信息数量统计'
                    this.streeUser = {
                        tableData:dataTwo && dataTwo.length > 0 ? dataTwo : [],
                        columns:[
                            {
                                title:'社区',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        on:{
                                        'click':()=>{
                                            // 点击跳转
                                            this.changeSelectList(params.row.orgCode)
                                        }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
            }else if(this.navList[this.navList.length - 1].orgLevel == '5'){
                let dataTwo = await this.leaveLastOrgChildInfo(this.navList[this.navList.length - 1].orgCode)
                this.tableTitle = '各服务主体建设情况'
                this.columnTitle = '各服务主体栏目统计'
                this.columnTitleOther = '服务主体发布数量统计'
                    this.streeUser = {
                        tableData:dataTwo && dataTwo.length > 0 ? dataTwo : [],
                        columns:[
                            {
                                title:'服务主体',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        style:{
                                            color:this.showBar? '#2d8cf0':'black',
                                            cursor:this.showBar? 'pointer':'auto'
                                        },
                                        on:{
                                        'click':()=>{
                                                // 点击跳转
                                                if(this.showBar)
                                                this.changeSelectList(params.row.orgCode)
                                            }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
            }
       },

       async getBackChange(){
        
         if(!this.navList[this.navList.length - 1].orgCode){
             console.log('点击了back事件1',this.navList[this.navList.length - 1].orgCode,this.navList[this.navList.length - 1].orgLevel);
             let data = await this.getSelectOrgChildInfo(this.portalInfo.orgCode)
             await this.getColumnNum(this.portalInfo.orgCode)
             this.getColumnTitle(data)
             
         }else{
             if(this.navList[this.navList.length - 1].orgLevel == '4'){
                 let data = await this.getSelectOrgChildInfo(this.navList[this.navList.length -1].orgCode)
                 this.getColumnTitle(data)
                 await this.getColumnNum(this.navList[this.navList.length -1].orgCode)
             }else if(this.navList[this.navList.length - 1].orgLevel == '5'){
                 let data = await this.selectOverallOrgManageInfo(this.navList[this.navList.length -1].orgCode)
                  this.getColumnTitle(data)
                  await this.getColumnNum(this.navList[this.navList.length -1].orgCode,'2')
                  
             }
             
         }
        
       },
        // 点击返回按钮跑接口
       getColumnTitle(data){
           if(data){
               if(!this.navList[this.navList.length - 1].orgLevel){
                   this.tableTitle = '各街道建设情况'
                   this.columnTitle = '各街道栏目统计'
                   this.columnTitleOther = '街道发布各类别信息数量统计'
                   this.streeUser = {
                        tableData:data && data.length > 0 ? data : [],
                        columns:[
                            {
                                title:'街道',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        on:{
                                        'click':()=>{
                                            // 点击跳转
                                            this.changeSelectList(params.row.orgCode)
                                        }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
               }else if(this.navList[this.navList.length - 1].orgLevel == '4'){
                   this.tableTitle = '各社区建设情况'
                   this.columnTitle = '各社区栏目统计'
                   this.columnTitleOther = '社区发布各类别信息数量统计'
                     this.streeUser = {
                        tableData:data && data.length > 0 ? data : [],
                        columns:[
                            {
                                title:'社区',
                                key:'orgName',
                                align:'center',
                                render:(h,params) => {
                                return h('div',[
                                    h('a',{
                                        on:{
                                        'click':()=>{
                                            // 点击跳转
                                            this.changeSelectList(params.row.orgCode)
                                        }
                                        }
                                    },params.row.orgName)
                                    ])
                                }
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
               }
           }
       },
       // 获取顶部栏目开通数，发布情况接口公共方法
       async getColumnNum(orgCode,isTrue){
           let columnData = await this.selectOrgInfo(orgCode)
           
            if(columnData && !columnData.length){
                  this.columnList = [
                          {title:'栏目数',num:columnData.columnAmount || 0},
                          {title:'子栏目数',num:columnData.columnChild || 0},
                          {title:'空白栏目',num:columnData.columnBlank || 0}
                      ]
                      this.sendList=[
                          {title:'发布数量',num: columnData.published + columnData.unpublished || 0},
                          {title:'公布中数量',num:columnData.published || 0},
                      ]
              }else{
                   this.columnList = [
                          {title:'栏目数',num: 0},
                          {title:'子栏目数',num: 0},
                          {title:'空白栏目',num: 0}
                      ]
                      this.sendList=[
                          {title:'发布数量',num: 0},
                          {title:'公布中数量',num:0},
                      ]
              }
              let columnDataOther = []
            if(isTrue && isTrue == '1') {
                let dateThird = this.getThirdMonth()
                let data = await this.leaveLastOrgChildInfo(orgCode)
                let dataTwo = await this.selectColumnFrequency({
                    orgCode,
                    startDate:dateThird.startDate,
                    endDate:dateThird.endDate
                })
                this.formatterPinlv(dataTwo)
                if(data && data.length > 0){
                    this.streeUser = {
                        tableData:data && data.length > 0 ? data : [],
                        columns:[
                            {
                                title:'管理员',
                                key:'orgName',
                                align:'center'
                            },
                            {
                                title:'服务主体数量',
                                key:'serviceAmount',
                                align:'center'
                            },
                            {
                                title:'管理员数量',
                                key:'staffAmount',
                                align:'center'
                            },
                        ]
                    }
                }
                return
            }
            if(isTrue && isTrue == '2'){
                columnDataOther = await this.selectOverallOrgManageInfo(orgCode)
            }else{
                columnDataOther = await this.selectOrgChildInfo(orgCode)
            }
              
              if(columnDataOther && columnDataOther.length){
                  this.columnUsage.barData = {
                                columns:['名称','栏目数','子栏目数'],
                                rows:[]
                            }
                        this.columnUsageTwo.barData = {
                            columns:['名称','发布数量','公布中数量'],
                            rows:[]
                        }
                        columnDataOther.map(item =>{
                            item.total = item.published + item.unpublished
                            this.columnUsage.barData.rows.push({
                                '名称':item.orgName,
                                '栏目数':item.columnAmount,
                                '子栏目数': item.columnChild
                            })
                            this.columnUsageTwo.barData.rows.push({
                                '名称':item.orgName,
                                '发布数量':(item.published || 0) + (item.unpublished || 0),
                                '公布中数量':item.published
                            })
                        })
                 this.columnUsage.tableData = columnDataOther
              }else{
                  this.columnUsage.barData = {
                                columns:['名称','栏目数','子栏目数'],
                                rows:[]
                            }
                  this.columnUsageTwo.barData = {
                      columns:['名称','发布数量','公布中数量'],
                      rows:[]
                  }
                  this.columnUsage.tableData = []
              }
       },

       getThirdMonth(){
        //获取当前日期
        var myDate = new Date();
        var nowY = myDate.getFullYear();
        var nowM = myDate.getMonth()+1;
        var nowD = myDate.getDate();
        var enddate = nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD);//当前日期
        //获取三十天前日期
        var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
        var lastY = lw.getFullYear();
        var lastM = lw.getMonth()+1;
        var lastD = lw.getDate();
        var startdate=lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD);//三十天之前日期
        return {startDate:startdate,endDate:enddate}
       },
        // 格式化频率数据
       formatterPinlv(data){
           this.lineUsage.lineData = {
                       columns:['日期','数量'],
                       rows:[]
           }
           if(data){
               data.map(item =>{
                   this.lineUsage.lineData.rows.push({
                       '日期':item.statDate || '',
                       '数量':item.createNum || 0
                   })
               })
           }
       }
    }
}
</script>

<style lang="less" scoped>
.card-view{
    display: flex;
    height: 120px;
    justify-content: center;
}
.bar-div{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    // border-top: 1px dashed #ccc;
    padding-top: 10px;
    // &:nth-last-child(1){
    //     border-bottom: none;   
    // }
}
.table-view{
    width: 100%;
    height: 200px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 20px;
}
.select-div{
    height: 30px;
    display: flex;
    .item{
        width: 100px;
        height: 30px;
        line-height:30px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        &:nth-child(2){
            border-left: none
        }
        &-active{
            background:#2d8cf0 ;
            color: #ffffff;
        }
    }
}
</style>